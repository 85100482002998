.button-primary {
  border: none;
  color: var(--color-secondary);
  background-color: var(--color-primary);
}
.button-secondary {
  border: 2px solid var(--color-secondary-dark);
  color: var(--color-primary-dark);
  background-color: var(--color-white);
}

.button {
  height: 44px;
  cursor: pointer;
}
.button {
  display: block;
  width: 90%;
  margin: 4px;
  transition: background-image 0.5s ease;
}
.button > a {
  text-decoration: none;
  display: block;
  color: inherit;
  text-align: center;
}
.button > a:link {
  text-decoration: none;
}

.button > a:visited {
  text-decoration: none;
}

.button > a {
  text-decoration: none;
}
.button-main > a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
.button-close {
  position: absolute;
  top: var(--space-medium);
  right: var(--space-medium);
  background: transparent;
  border: none;
  cursor: pointer;
}
.button-primary:hover {
  background-color: var(--color-secondary);
  color: var(--color-primary);
   border: 2px solid var(--color-secondary-dark);
}
.button-secondary:hover {
  border: 2px solid var(--color-primary-dark);
}

.modal-background {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 3;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-secondary-transparent);
}
.modal-info-wrapper {
  padding: 0 var(--space-medium);
  display: flex;
  flex-direction: column;
}
.modal-info-wrapper a {
  align-self: center;
  width: 80%;
}
.modal-container {
  position: relative;
  background-color: var(--color-white);
  width: calc(100% - 30px);
}
.modal-detail {
  max-width: 100%;
  min-height: 518px;
  margin: auto;
  display: grid;
  grid-gap: var(--space-medium);
  padding-bottom: var(--space-medium);
}
.modal-image-container {
  display: flex;
  justify-content: center;
}
.modal-image-wrapper img {
  width: 100%;
  object-fit: cover;
}
.pill-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.modal-info-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.modal-info-container > button {
  align-self: center;
}
.modal-description,
.modal-title {
  padding: 0px var(--space-small);
}
@media only screen and (min-width: 720px) {
  .modal-container {
    margin: auto;
    max-width: 960px;
  }
  .modal-detail {
    grid-template-columns: 60% auto;
    padding: var(--space-medium);
  }
  .modal-image-container {
    padding: var(--space-medium);
    align-items: center;
  }
  .modal-info-container {
    width: 327px;
  }
  .modal-image-wrapper img {
  height: 100%;

}
}

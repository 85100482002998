footer {
  width: 100%;
  height: 85px;
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}
footer a > svg {
  height: 40px;
  width: 40px;
  color: #c9cde6;
}
footer > ul li {
  list-style: none;
  margin: 0;
  display: inline-block;
  margin: var(--space-medium);
}

footer > ul a {
  text-decoration: none;
  color: var(--color-secondary);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body img {
  max-width: 100%;
  max-height: 100%;
}
.App {
  font-family: var(--font-family);
}
html {
  scroll-behavior: smooth;
}

.picture {
  text-align: center;
}
.picture > img {
  margin: auto;
  max-width: 100%;
  border-radius: 5px;
}
@media only screen and (min-width: 920px) {
  .picture > img {
    max-width: 335px;
  }
}

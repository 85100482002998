.hero {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--space-big);
  line-height: 44px;
  text-align: center;
  background-image: url(../../assets/images/portfolio-bg.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-color: var(--color-secondary);
}
.hero-overlay{
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--color-secondary-transparent);
}
@media only screen and (min-width: 920px) {
  .hero-container {
    max-width: 500px;
  }
}

.card {
  width: 156px;
  min-height: 172px;
  background-color: var(--color-secondary);
  text-align: center;
  border: none;
  padding: 0;
  cursor: pointer;
  margin: var(--space-small);
}
.thumbnail {
  height: 116px;
  width: 156px;
  position: relative;
  display: flex;
  justify-content: center;
}
.thumbnail > img {
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.thumbnail-overlay {
  position: absolute;
  top: 0;
  background-color: var(--color-secondary-transparent);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.thumbnail-overlay > span {
  width: inherit;

  font-weight: bold;
  color: var(--color-white);
}
.card .title {
  height: 48px;
}
.title {
  padding: var(--space-small) 0;
}
@media only screen and (min-width: 1025px) {
  .card {
    width: 224px;
    min-height: 236px;
  }
  .thumbnail {
    width: 224px;
    height: 166px;
  }
}

.section-layout-container {
  width: 100%;
}
.section-layout {
  display: grid;
  margin: auto;
  position: relative;
  padding: var(--space-big) var(--space-medium);
  justify-items: center;
}
.section-description {
  max-width: 450px;
}
.section-layout > h2 {
  justify-self: start;
}
.section-content {
  display: flex;
  justify-content: center;
  width: 80%;
  padding: var(--space-big) 0;
}
#about .section-content {
  order: 1;
}
#portfolio {
  background-color: var(--color-secondary);
}
#contacts {
  background-color: var(--color-secondary);
}
#portfolio .section-content {
  display: grid;
  grid-template-columns: auto auto;
}
#tech .section-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
}
@media only screen and (min-width: 485px) {
  .section-layout > h2 {
    justify-self: center;
  }
}
@media only screen and (min-width: 920px) {
  .section-layout {
    max-width: 1120px;
    justify-items: start;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .section-layout > h2 {
    grid-column: 1/4;
    justify-self: start;
  }
  .section-description {
    grid-column: 1/2;
  }
  .section-content {
    grid-column: 2/4;
    padding: 0;
    width: 100%;
  }
  #portfolio .section-content {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}

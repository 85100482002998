nav {
  min-height: 60px;
  background-color: var(--color-secondary-dark);
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 1;
}
.logo-desktop {
  display: none;
}
.navigation-menu {
  max-height: 30px;
  background-color: var(--color-primary);
  width: 90%;
  margin: auto;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px var(--space-small);
}
.navigation-menu > a {
  color: var(--color-white);
  font-size: var(--font-nav-mobile);
  text-decoration: none;
}
.menu-link:nth-of-type(1) {
  order: 3;
}
.menu-link:nth-of-type(2) {
  order: 1;
}
.menu-link:nth-of-type(3) {
  order: 2;
}
.menu-link:nth-of-type(4) {
  order: 4;
}
.menu-link:nth-of-type(5) {
  order: 5;
}
@media only screen and (min-width: 920px) {
  .navigation-menu {
    max-height: 100%;
    height: 100%;
    border-radius: 0;
    flex-wrap: wrap;
    background-color: transparent;
    justify-content: flex-end;
  }
  .logo-mobile {
    display: none;
  }
  .logo-desktop {
    display: block;
  }
  nav {
    background-color: var(--color-primary);
  }
  .navigation-menu > a {
    font-size: var(--font-nav-desktop);
  }
  .menu-link {
    margin-left: var(--space-big);
    font-size: 17px;
  }
  .menu-link:nth-of-type(1) {
    order: 1;
    position: absolute;
    left: 16px;
  }
  .menu-link:nth-of-type(2) {
    order: 2;
  }
  .menu-link:nth-of-type(3) {
    order: 3;
  }
}

.tech-icon-cell {
  width: 100%;
  display: flex;
  justify-content: center;
}
.tech-icon-background {
  width: 44px;
  color: var(--color-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tech-icon-background img {
  width: 100%;
}
.tech-name {
  color: var(--color-primary);
  font-size: 12px;
}
@media only screen and (min-width: 920px) {
  .tech-icon-background {
    width: 60px;
  }
}

:root {
  /* Colors */
  --color-primary: #26337a;
  --color-primary-dark: #26337a;
  --color-secondary: #fbfbfb;
  --color-secondary-dark: #8c9cc3;
  --color-white: #ffffff;
  --color-gray-2: #c4c4c4;

  --color-secondary-transparent:  #26337a31;

  /* Spacing */
  --space-extra-big: 44px;
  --space-big: 32px;
  --space-medium: 16px;
  --space-small: 8px;

  /*Screen*/
  --screen-big: 768px;
  /* Fonts */
  --font-family:'Nanum Gothic', sans-serif;
  --font-heading-1-mobile: 34px;
  --font-heading-1-desktop: 50px;

  --font-heading-2-mobile: 22px;
  --font-heading-2-desktop: 42px;

  --font-heading-3-mobile: 17px;
  --font-heading-3-desktop: 24px;

  --font-text-mobile: 17px;
  --font-text-desktop: 18px;

  --font-button-mobile: 14px;
  --font-button-desktop: 16px;

  --font-nav-mobile: 11px;
  --font-nav-desktop: 17px;

  --font-pill: 12px;
}

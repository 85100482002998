@font-face {
  font-family: "Nanum Gothic";
  src: url("../assets/fonts/NanumGothic-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "Nanum Gothic";
  src: url("../assets/fonts/NanumGothic-Regular.ttf");
  font-weight: normal;
}

h1 {
  font-size: var(--font-heading-1-mobile);
}

h2 {
  font-size: var(--font-heading-2-mobile);
  margin: var(--space-medium) 0;
}
h3 {
  font-size: var(--font-heading-3-mobile);
  line-height: 1.45;
  margin: 0;
}
@media (min-width: 920px) {
  h1 {
    font-size: var(--font-heading-1-desktop);
    line-height: 56px;
  }
  h2 {
    font-size: var(--font-heading-2-desktop);
    line-height: 48px;
  }
}
